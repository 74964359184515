import posthog from 'posthog-js'

export const HOST_SIELBLEU = 'sielbleu'
export const HOST_FIZZUP = 'fizzup'
export const HOST_SCHOOL = 'school'

export const initAnalytics = () => {
    if (typeof window === 'undefined') {
        return
    }

    const type = hostType()

    switch (type) {
        case HOST_SIELBLEU:
            if (!process.env.NEXT_PUBLIC_POSTHOG_API_KEY_SIELBLEU) {
                // posthog is not configured
                return
            }
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY_SIELBLEU, {
                api_host: 'https://eu.i.posthog.com',
                autocapture: false,
                persistence: 'localStorage+cookie',
                capture_pageview: true,
                debug: process.env.NODE_ENV === 'development',
                cross_subdomain_cookie: true,
            })
            break
        case HOST_FIZZUP:
        case HOST_SCHOOL:
            if (!process.env.NEXT_PUBLIC_POSTHOG_API_KEY_FIZZUP) {
                // posthog is not configured
                return
            }
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY_FIZZUP, {
                api_host: 'https://sonic.fizzup.com',
                ui_host: 'https://eu.posthog.com',
                autocapture: false,
                persistence: 'localStorage+cookie',
                capture_pageview: true,
                debug: process.env.NODE_ENV === 'development',
                cross_subdomain_cookie: true,
            })
    }
}

export const hostType = () => {
    const host = window?.location?.host

    switch (true) {
        case host?.includes('sielbleu'):
            return HOST_SIELBLEU
        case host?.includes('school'):
        case host?.includes('fizzup-ecole'):
            return HOST_SCHOOL
        case host?.includes('fizzup'):
            return HOST_FIZZUP
        default:
            return null
    }
}
